@import 'assets/scss/variables.scss';

.content-page-header {
    color: #FFF;
    background-color: var(--bs-primary);
}

.content-page-section {
    h2 {
        margin-top: 2rem;
        margin-bottom: .75rem;
    }

    h3 {
        &:not(:first-child) {
            margin-top: 2rem;
        }

        &:not(:last-child) {
            margin-bottom: 1.5rem;
        }
    }

    a {
        color: var(--bs-link-color);
        font-weight: 600;
        
        &:not(.btn) {
            text-decoration: underline;
        }
    }

    img {
        max-width: 100% !important;
        height: auto !important;
    }

    .img-guide {
        text-align: center;
        margin: 1.5rem auto;

        img {
            border-radius: .875rem;

            @media (min-width: 768px) {
                max-width: 434px !important;
                margin-left: auto;
                margin-right: auto;
            }
        }
    }
}

.user-manual-item {
    margin-bottom: 3.5rem;
    
    h3 {
        display: inline-block;
        padding: .125rem .625rem .25rem 0;
        border-radius: .375rem;
        border-bottom-left-radius: 1rem;
        font-size: 1rem;
        margin-bottom: 0 !important;
        background-color: var(--bs-gray-200);
    }

    p {
        margin-left: 1rem;
        margin-top: -.75rem;
        padding: 1.125rem .75rem .75rem;
        border: 1px solid var(--bs-gray-200);
        border-radius: .5rem;
    }

    .item-index {
        position: relative;
        font-size: 1.5rem;
        color: #FFF;
        background-color: var(--bs-primary);
        padding: .25rem .5rem;
        border-bottom-left-radius: 1rem;
        border-bottom-right-radius: 1rem;
        vertical-align: -2px;
        margin-right: .5rem;
        box-shadow: var(--bs-box-shadow);

        .item-index-icon {
            min-width: 42px;
            position: absolute;
            bottom: 99%;
            left: 0;
            background-color: var(--bs-primary);
            padding: .25rem .5rem;
            border-radius: .75rem;
            border-bottom-left-radius: 0;
            text-align: center;
        }
    }
}