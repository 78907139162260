.countdown-timer-wrapper {
    display: inline-block;
    // border-radius: 24px;
    // padding: 1px;
    overflow: hidden;
    // background: linear-gradient(75deg, #FFFFFF, rgba(255, 255, 255, 0.08) 80%);
    margin-top: 2rem;

    .countdown-timer-bg {
        position: relative;
        // background-color: #1D1D1E;
        // border-radius: 23px;
        // padding: .5rem 2rem 1.25rem;

        // &::before {
        //     content: '';
        //     display: block;
        //     position: absolute;
        //     z-index: 0;
        //     top: -40px;
        //     left: -40px;
        //     width: 80px;
        //     height: 80px;
        //     border-radius: 50%;
        //     background: linear-gradient(5deg, #E55100, #5500FF 80%);
        //     filter: blur(40px);
        // }

        // &::after {
        //     content: '';
        //     display: block;
        //     position: absolute;
        //     z-index: 0;
        //     bottom: -40px;
        //     right: -40px;
        //     width: 80px;
        //     height: 80px;
        //     border-radius: 50%;
        //     background: linear-gradient(5deg, #E55100, #5500FF 80%);
        //     filter: blur(40px);
        // }
    }

    .countdown-timer {
        position: relative;
        z-index: 1;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 1rem;
    
        .counter {
            text-align: center;
            // background-color: rgba(30, 30, 31, .5);
            border-radius: 1rem;
            padding: .5rem 0 1.25rem;

            // background-color: rgba(30, 30, 31, 0.05);
            // border: 1px solid rgba(255, 255, 255, 0.3);
            // box-shadow: 0px -3px 60px 0px rgba(255, 255, 255, 0.1) inset;
            
            .counter-number {
                font-size: 4rem;
                font-weight: 700;
                margin: 0;
                width: 100px;
                text-align: center;
            }
    
            .counter-label {
                font-size: .875rem;
                font-weight: 500;
                color: var(--bs-body-color);
                text-transform: uppercase;
                margin: 0;
                margin-top: -8px;
            }
        }
    
        span {
            font-size: 3rem;
            font-weight: 700;
        }
    }

    @media (max-width: 767px) {
        display: block;

        .countdown-timer {
            justify-content: space-between;
            gap: .25rem;

            .counter {
                flex-grow: 1;

                .counter-number {
                    width: unset;
                    font-size: 2.5rem;
                }

                .counter-label {
                    margin-top: -12px;
                    font-size: .75rem;
                    letter-spacing: -2%;
                }
            }

            span {
                font-size: 2.25rem;
            }
        }
    }
}

.countdown-timer-description {
    color: #D3E4FE !important;

    @media (max-width: 991px) {
        color: #A7C9FE !important;
    }
}