@import 'assets/scss/variables.scss';

.home-header {
    padding-top: 10rem;
    padding-bottom: 0;
    display: flex;
    align-items: center;

    .section-bg {
        background-position: top center;
    }

    .header-video-bg {
        margin-top: 10rem;
    }

    .sec-header {
        .sec-line-title {
            color: var(--bs-primary);
            font-size: 1.25rem;
            font-weight: 800;

            .line {
                position: relative;
                vertical-align: 6px;
                display: inline-block;
                width: 100px;
                height: 3px;
                background-color: var(--bs-primary);
                margin-right: 1rem;
                border-radius: 3px;
            }
        }

        .sec-title {
            position: relative;
            font-size: 2.5rem;
            font-weight: 800;

            &.has-bg {
                display: inline-block;

                &::before {
                    content: '';
                    position: absolute;
                    z-index: -1;
                    left: 42%;
                    height: 100%;
                    width: 75%;
                    background-color: #f8d259;
                }
            }
        }

        .sec-header-badge {
            position: relative;
            font-size: 1rem;
            padding: 0.5rem 1rem;
        }
    }

    .header-image-price-tag {
        position: absolute;
        bottom: 20%;
        right: 10%;
        padding: .375rem 1rem;
        font-size: 1rem;
        font-weight: 700;
        white-space: nowrap;
        box-shadow: 4px 4px 32px rgba($color: (var(--bs-primary-rgb)), $alpha: 0.25);
        animation: breathing 1.5s infinite ease-in-out;
    }

    @keyframes breathing {
        0%      { transform: scale(1); }
        50%     { transform: scale(1.05); }
        100%    { transform: scale(1); }
    }

    @media (max-width: 991px) {
        padding-top: 6rem;
        padding-bottom: 3rem;

        .sec-header {
            .sec-line-title {
                font-size: 1rem;

                .line {
                    width: 50px;
                    margin-right: .5rem;
                    vertical-align: 5px;
                }
            }

            .sec-title {
                font-size: 1.75rem;
                margin-bottom: 1rem;
            }
    
            .sec-description {
                font-size: 1rem;
            }
        }
    }

    @media (max-width: 767px) {
        .section-bg {
            padding-bottom: calc(5rem + 96px);
        }

        .header-video-bg {
            margin-top: 20vh;
        }
    }
}

.highlight-section {
    padding-bottom: 2rem;

    .highlight-item {
        padding: 2rem 0;
        text-align: center;

        p {
            font-size: 2.25rem;
            font-weight: 700;
            margin-bottom: .25rem;
        }

        h6 {
            font-weight: 600;
            color: var(--bs-secondary);
        }
    }

    @media (max-width: 991px) {
        padding-top: 2rem;
        padding-bottom: 1rem;

        .highlight-item {
            padding: 1rem 0;
            
            p {
                font-size: 1.75rem;
            }
        }
    }
}

.features-section {
    .container-xl {
        position: relative;
        z-index: 1;
    }

    .feature-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 1rem;
        margin-bottom: 2rem;

        .item-icon {
            width: 80px;
            flex-shrink: 0;
            color: var(--bs-primary);
            text-align: center;
        }

        h3 {
            font-size: 1.25rem;
            margin-bottom: .25rem;
        }

        p {
            margin-bottom: 0;
        }
    }
}

.join-section {
    .section-bg {
        opacity: .1;
    }
}

.partner-section {
    color: #FFF;

    .sec-header {
        margin-bottom: 4rem;
    }

    .partner-img-slider {
        .swiper-wrapper {
            align-items: center;
        }
    }

    .marketpartner-wrapper {
        padding: 1.25rem;
        padding-top: 2rem;
        border-radius: 1rem;
        border-bottom: 10px solid var(--bs-primary);
        color: var(--bs-body-color);
        background-color: #FFF;
        margin-top: 4rem;
        margin-bottom: -12rem;

        .sec-header {
            .sec-title {
                font-size: 2.25rem;
                margin-bottom: 1rem;
            }
        }
    }
}

.news-section {
    padding-top: 12rem;
    z-index: 2;

    .article {
        position: relative;
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 1rem;
        overflow: hidden;
        background-color: #FFF;
        filter: drop-shadow(2px 2px 8px rgba(0, 0, 0, .05));
        transition: background-color .15s ease;

        &:hover {
            img {
                transform: scale(1.05);
            }
        }
    
        .article-image {
            position: relative;
            z-index: 1;
            height: 200px;
            overflow: hidden;
    
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                transition: transform .25s ease;
            }
        }
    
        .article-info {
            padding: 1.5rem 1rem;
    
            .article-title {
                font-size: 1.5rem;
                margin-bottom: .75rem;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                align-self: stretch;
                display: -webkit-box;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .article-description {
                margin-bottom: 0;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3;
                align-self: stretch;
                display: -webkit-box;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
}

.social-section {
    background-color: rgba(0, 0, 0, .5);
    background: linear-gradient(to bottom, rgba(var(--bs-primary-rgb), 0.1), rgba(var(--bs-primary-rgb), 0));

    .navbar-social-link {
        img {
            width: 72px;
            height: 72px;
            object-fit: contain;
        }
    }

    .navbar-social-page {
        .nav-link {
            font-weight: 600;
            color: var(--bs-body-color);
        }
    }

    @media (max-width: 767px) {
        .navbar-social-link {
            img {
                width: 64px;
                height: 64px;
            }
        }
    }
}

.cta-section {
    padding: 8rem 0;

    .section-bg {
        background-position: top center;
    }
}